import { createAsyncThunk } from '@reduxjs/toolkit';
import { signOut } from 'firebase/auth';
import { ProjectId } from '@/types/interface';
import { setToken } from '@/utils/setToken';
import auth from '../../../firebase';
import {
  register,
  login,
  logout,
  self,
  queryUserPhotoBriefByProjectId,
} from './usersAPI';
import {
  LoginRequest,
  RegisterRequest,
  UserPhotoBriefByProjectId,
} from './interface';

export const fetchThirdRegisterThunk = createAsyncThunk(
  'users/fetchThirdRegister',
  async (data: RegisterRequest) => {
    const res = await register(data);
    return res.data;
  },
);

export const fetchRegisterThunk = createAsyncThunk(
  'users/fetchRegister',
  async (data: RegisterRequest, { rejectWithValue }) => {
    try {
      const res = await register(data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchLoginThunk = createAsyncThunk(
  'user/fetchLogin',
  async (data: LoginRequest, { rejectWithValue }) => {
    try {
      const res = await login(data);
      const { token, refreshToken } = res.data.data;
      if (token && refreshToken) {
        setToken(token, refreshToken);
      }
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUserSelfThunk = createAsyncThunk(
  'user/fetchUserSelf',
  async () => {
    const res = await self();
    return res.data.data;
  },
);

export const fetchLogoutThunk = createAsyncThunk(
  'user/fetchLogout',
  async () => {
    await signOut(auth);
    const res = await logout();
    if (res.data) {
      localStorage.removeItem('token');

      return res.data;
    }
  },
);

export const fetchQueryUserPhotoBriefByProjectIdThunk = createAsyncThunk(
  'user/fetchQueryUserPhotoBriefByProjectId',
  async ({ ProjectId }: ProjectId, { rejectWithValue }) => {
    try {
      const res: UserPhotoBriefByProjectId =
        await queryUserPhotoBriefByProjectId(ProjectId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
