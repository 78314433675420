import { Modal } from '@mui/material';
import React from 'react';

interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

const VideoModal = ({
  open,
  onClose,
  children,
  className,
}: VideoModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="flex items-center justify-center"
    >
      <div className={className}>{children}</div>
    </Modal>
  );
};

export default VideoModal;
